.summary-container {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
    border-radius: 8px;
    max-width: 900px;
    margin: 0 auto;
    border: 1px solid #b1b1b1;
}

body.light .summary-container {
    background-color: #f9f9f9;
    color: #000000;
}

body.dark .summary-container {
    background-color: #232323;
    color: #ffffff;
}

.image-container {
    flex: 1;
    display: flex;
    justify-content: center;
}

.profile-image {
    width: 300px;
    height: auto;
    border-radius: 50%;
}

.content-container {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
}

.text-container {
    text-align: left;
    margin-bottom: 20px;
}

.text-container h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: inherit;
}

.text-container h3 strong {
    font-size: 1.6rem;
    color: inherit;
}

.text-container p {
    font-size: 1rem;
    line-height: 1.5;
    color: inherit; 
}

.links-container {
    display: flex;
    gap: 10px;
}

.link-icon {
    width: 30px;
    height: 30px;
    cursor: pointer;
}



.link-circle {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #ffffff;
  transition: background-color 0.3s, transform 0.3s;
  line-height: 0;
  border: 1px solid #dadada;
}

.link-circle:hover {
  transform: scale(1.1);
  background-color: #dadada;
}

@media (max-width: 767px) {
    .summary-container {
        flex-direction: column;
    }
}


