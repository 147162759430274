.education-container {
    padding: 10px;
    max-width: 800px;
    margin: 0 auto;
    padding-top: 0;
    margin-top: 0;
}

.education-institution {
    font-size: 20px;
    font-weight: bold;
    font-style: italic;
    text-align: left;
}

.program-title {
    display: flex;
    text-align: left;
}

.program-role {
    font-weight: bold;
    font-size: 16px;
    
}

.program-type {
    font-size: 16px;
    font-style: italic;
    align-self: flex-end;
    margin-left: 4px;
}



.education-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.education-item {
    padding: 15px;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid #ddd;
    position: relative;
    margin-bottom: 20px;
    max-height:auto;
    transition: background-color 0.3s, box-shadow 0.3s;
}

.education-item.light {
    background-color: #f9f9f9;
}
.education-item.light:hover {
    background-color: #e0e0e0; 
}
.education-item.dark {
    background-color: #232323;
}
.education-item.dark:hover {
    background-color: #303030; 
}

.education-item.expanded {
    max-height:auto;
}

.education-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.education-left, .education-right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.education-summary-left, .education-summary-right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.eduation-detail-box-header {
    font-size: 20px;
    font-weight: bold;
    font-style: italic;
}

@media (max-width: 650px) {
    .education-summary {
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
    }

    .education-summary-right {
        align-items: flex-start;
        width: 100%;
    }
}

.view-details-button {
    align-self: flex-start;
    margin-top: 10px;
}

.eduation-details-box {
    margin-top: 15px;
    position: relative;
    display: block;
}

.program-location-duration {
    font-size: 16px;
    font-style: italic;
}
