.App {
  text-align: center;
}


body.light {
  background-color: #ffffff;
  color: #000000;
}

body.dark {
  background-color: #000000;
  color: #ffffff;
}


.body {
  padding-left: 13rem;
  padding-right: 13rem;
  padding-top: 1em;
  margin-bottom: 30px;
}

@media (max-width: 1400px) {
  .body {
    padding-left: 10rem;
    padding-right: 10rem;
  }
}

@media (max-width: 991px) {
  .body {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

@media (max-width: 767px) {
  .body {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (max-width: 575px) {
  .body {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.buttons-container {
  margin-top: 2rem;
  display: flex;
  gap: 10px;
  justify-content: center;
  flex-wrap: wrap;
}

.buttons-container button {
  padding: 0.75rem 1.5rem;
  background-color: #0073e6;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  flex: 1 1 auto;
  max-width: 200px;
}

.buttons-container button:hover {
  background-color: #005bb5;
  transform: translateY(-3px);
}

.buttons-container button:active {
  transform: translateY(1px);
}

.buttons-container button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 115, 230, 0.5);
}

@media (max-width: 600px) {
  .buttons-container {
    flex-direction: column;
    align-items: center;
  }

  .buttons-container button {
    width: 100%; 
    max-width: 300px;
  }
}

.menu-icons-container {
  margin-top: 2rem;
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
}

.menu-icon-circle {
  position: relative;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #ffffff; 
  transition: background-color 0.3s, transform 0.3s;
  cursor: pointer;
  border: 1px solid #000000;
}


.menu-icon {
  width: 30px;
  height: 30px;
}

.menu-icon-circle:hover {
  background-color: #cbcbcb;
  transform: scale(1.2);
}

.menu-icon-circle:active {
  transform: translateY(1px);
}

.menu-tooltip {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px 8px;
  border-radius: 5px;
  font-size: 0.75rem;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none; 
}

.menu-icon-circle:hover .menu-tooltip {
  opacity: 1;
}