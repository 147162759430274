.navbar {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  height: 20px;
  align-items: center;
}

.toggle-switch {
  width: 60px;
  height: 30px;
  background-color: #ccc;
  border-radius: 25px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
  box-sizing: border-box;
  margin-left: 5px;
}

.toggle-switch:first-child {
  margin-left: 0;
}

.toggle-switch:hover {
  background-color: #bbb;
}

.toggle-knob {
  width: 28px;
  height: 28px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  left: 1px;
  transition: left 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  font-weight: bold;
  color: #000;   
}

.toggle-knob-right {
  left: 31px;
}

.toggle-text {
  display: none;
}

.toggle-image {
  width: 16px; 
  height: 16px;
  object-fit: contain;
}
